import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, delay, mergeMap, Observable, of, retry, retryWhen} from "rxjs";
import {error} from "@angular/compiler-cli/src/transformers/util";

export const maxRetries = 2;
export const delayMs = 2000;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			retry(maxRetries),
			delay(delayMs),
			catchError((err: HttpErrorResponse) => {
				throw err;
			})
		)
	};
}