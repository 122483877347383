import {Routes} from '@angular/router';
import {authGuard} from "./guard/auth.guard";

export const routes: Routes = [
	{
		path: 'auth',
		loadComponent: () => import('./feature/auth/auth.component').then(mod => mod.AuthComponent),
		children: [
			{path: '', redirectTo: 'sign-in', pathMatch: 'full'},
			{
				path: 'sign-in',
				loadComponent: () => import('./feature/auth/login/login.component').then(mod => mod.LoginComponent),
			},
			{
				path: 'sign-up',
				loadComponent: () => import('./feature/auth/auth-create/auth-create.component').then(mod => mod.AuthCreateComponent),
			},
		]
	},
	{
		path: '',
		loadComponent: () => import('./feature/components/main.component').then(mod => mod.MainComponent),
		canActivate: [authGuard],
		children: [
			{path: '', redirectTo: 'rental', pathMatch: 'full'},
			{
				path: 'rental',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/rental/rental.component').then(mod => mod.RentalComponent),
			},
			{
				path: 'rental/create',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/rental/create/create.component').then(mod => mod.CreateComponent),
			},
			{
				path: 'rental/edit/:id',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/rental/edit/edit.component').then(mod => mod.EditComponent),
			},
			{
				path: 'rental/detail/:id',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/rental/detail/detail.component').then(mod => mod.DetailComponent),
			},


			{
				path: 'device/detail/:id',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/device/detail/detail.component').then(mod => mod.DetailComponent),
			},
			{
				path: 'device/edit/:id',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/device/edit/edit.component').then(mod => mod.EditComponent),
			},

			///////////////////////////////////
			// マスター関係
			///////////////////////////////////
			{
				path: 'master',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/master/master.component').then(mod => mod.MasterComponent),
			},
			// 機器種別マスター
			{
				path: 'master/type/create',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/master/master-device-type/create/create.component').then(mod => mod.CreateComponent),
			},
			{
				path: 'master/type/edit/:id',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/master/master-device-type/edit/edit.component').then(mod => mod.EditComponent),
			},
			// 機器マスタ
			{
				path: 'master/device/create',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/master/master-device/create/create.component').then(mod => mod.CreateComponent),
			},
			{
				path: 'master/device/edit/:id',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/master/master-device/edit/edit.component').then(mod => mod.EditComponent),
			},
			//
			{
				path: 'master/capacity/create',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/master/master-device-capacity/create/create.component').then(mod => mod.CreateComponent),
			},
			{
				path: 'master/capacity/edit/:id',
				canActivateChild: [authGuard],
				loadComponent: () => import('./feature/components/master/master-device-capacity/edit/edit.component').then(mod => mod.EditComponent),
			},

		]
	}

];
