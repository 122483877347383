import {ApplicationConfig, LOCALE_ID} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS, provideHttpClient, withFetch} from "@angular/common/http";
import {ErrorInterceptor} from "./interceptors/error-interceptor.service";
import {PersistentService} from "./service/persistent.service";
import { BrowserStorageService} from "./service/storage.service";

import localeJa from '@angular/common/locales/ja';
import {registerLocaleData} from '@angular/common';
registerLocaleData(localeJa);

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(routes),
		provideAnimationsAsync(),
		provideHttpClient(withFetch()),
		PersistentService,
		BrowserStorageService,
		// {provide: BROWSER_STORAGE, useFactory: () => localStorage},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{ provide: LOCALE_ID, useValue: 'ja-JP' },
	],
};
