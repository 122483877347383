import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../service/auth.service";
import {PersistentService} from "../service/persistent.service";

export const authGuard: CanActivateFn = (route, state) => {

  const svc = inject(PersistentService);
  const router = inject(Router);

  if (svc.token != null) {
    // TODO: ここらへんにTokenが正しいことをチェックするAPI入れとくべき
    return true;
  }

  return router.parseUrl('/auth/sign-in');
};
