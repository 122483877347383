import {Component, inject, OnInit, SkipSelf} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {PersistentService} from "./service/persistent.service";
import {BrowserStorageService} from "./service/storage.service";


@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	title = 'sas-admin-client'

	private router = inject(Router);
	private persistentSvc = inject(PersistentService);

	private storageSvc = inject(BrowserStorageService);

	ngOnInit() {

		const token = this.storageSvc.get('t');
		if (token) {
			this.persistentSvc.token = token;
		}

	}

}
